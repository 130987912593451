import { useState, useContext, createContext, useEffect } from 'react';

import { useClerk } from '@clerk/clerk-react';
import { useSandpack } from '@codesandbox/sandpack-react';

// Components
import ProjectsView from './ProjectsView';
import Popup from './Popup';
import EditableText from './EditableText';
import Help from './Help';
import ShareView from './ShareView';
import { ToolbarButton } from './ToolbarButton';

// Hooks
import { useKeyboardShortcut } from '../hooks/keyboardShortcut';

// Icons
import { GrDownload, GrShareOption } from 'react-icons/gr';
import { CgStack } from 'react-icons/cg';
import { BiSolidHelpCircle } from 'react-icons/bi';
import { MdLaunch } from 'react-icons/md';

// Utilities
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { UpgradeButton } from './UpgradeButton';
import { ProjectContextType } from '../utils/types';
import UpgradeModal from './UpgradeModal';
import { PLANS } from '../constants/user';
import { UserDropdown } from './UserDropdown';
import { buttonStyles, inlineIconStyles } from '../constants/toolbar';

export const ProjectContext = createContext<ProjectContextType>({});

export const ToolBar = function () {
  const {
    projectName,
    currentProjectId,
    newProject,
    loadProject,
    projects,
    deleteProject,
    setCommandBarOpen,
    loading,
    updateProject,
    saveProject = () => {},
    firstLaunch,
    undoManager,
    plan,
    isNewProjectAllowed,
    projectsLoading,
    isFirebaseUserLoading,
  } = useContext<ProjectContextType>(ProjectContext);

  const { openSignIn, user } = useClerk();
  const userSignedIn: boolean = !!user && !!user.id;

  const { sandpack } = useSandpack();
  const { files } = sandpack;

  // Popup states
  const userHasProjects = projects && Object.keys(projects).length > 0;
  const [helpPopupOpen, setHelpPopupOpen] = useState<boolean>(
    Boolean(firstLaunch),
  );
  const [projectsPopupOpen, setProjectsPopupOpen] = useState<boolean>(
    !firstLaunch && userHasProjects && !currentProjectId,
  );

  const [sharePopupOpen, setSharePopupOpen] = useState<boolean>(false);
  const [showUpgradeModal, setShowUpgradeModal] = useState<boolean>(false);

  // Download the project as a zip file.
  const download = () => {
    const zip = new JSZip();
    for (const filePath in files) {
      zip.file(filePath, files[filePath].code);
    }
    zip.generateAsync({ type: 'blob' }).then(function (content) {
      saveAs(content, `${projectName}.zip`);
    });
  };

  // Delete the project.
  const deleteProjectHandler = async (projectId: string) => {
    if (projectsLoading) {
      return;
    }
    deleteProject(projectId);
    if (currentProjectId === projectId) {
      newProject?.();
    }
  };

  // When command-P is pressed, open the command bar.
  useKeyboardShortcut([
    { key: 'p', callback: () => setCommandBarOpen?.(true) },
    { key: 'k', callback: () => setCommandBarOpen?.(true) },
    { key: 'z', shift: true, alt: true, callback: () => undoManager.undo() },
  ]);

  const handleSaveProject = (projectName?: string) => {
    if (isNewProjectAllowed || currentProjectId) {
      saveProject(projectName);
    } else {
      setShowUpgradeModal(true);
    }
  };

  useEffect(() => {
    if (userSignedIn && !currentProjectId && !projectsLoading && projects)
      setProjectsPopupOpen(true);
  }, [userSignedIn, projectsLoading, currentProjectId]);

  return (
    <>
      <div
        className={`p-2 flex flex-wrap bg-slate-100 justify-between gap-4 relative z-20`}
      >
        {/* Left side toolbar items */}
        <div
          className={`flex justify-between items-center pl-1 w-full md:w-auto`}
        >
          {/* Projects view popup */}
          <Popup
            disabled={loading}
            open={projectsPopupOpen}
            label={<span className="inline-block ml-1">All Projects</span>}
            Icon={<CgStack size="18" className={inlineIconStyles} />}
            callback={(open) => {
              if (userSignedIn) {
                if (
                  !currentProjectId &&
                  open === false &&
                  !isNewProjectAllowed &&
                  !showUpgradeModal &&
                  !isFirebaseUserLoading
                ) {
                  setShowUpgradeModal(true);
                } else if (typeof open === 'boolean') {
                  setProjectsPopupOpen(open);
                }
              } else {
                openSignIn();
              }
            }}
          >
            <ProjectsView
              projects={projects}
              callback={(projectId) => {
                projectId ? loadProject?.(projectId) : newProject?.();
                setProjectsPopupOpen(false);
              }}
              deleteCallback={deleteProjectHandler}
              toggleUpgradeModal={() => {
                setShowUpgradeModal(true);
                setProjectsPopupOpen(false);
              }}
              projectsLoading={projectsLoading}
              isNewProjectAllowed={isNewProjectAllowed}
            />
          </Popup>
          {/* Help popup */}
          <Popup
            disabled={loading}
            open={helpPopupOpen}
            Icon={
              <BiSolidHelpCircle
                size="20"
                className={`tablet:mr-2 mr-0 inline ${inlineIconStyles}`}
              />
            }
            label={<span className="hidden tablet:inline">Help</span>}
            callback={setHelpPopupOpen}
          >
            <Help />
          </Popup>
        </div>
        {/* Editable project name */}
        <h1 className="ml-auto mr-auto mb-0.5 text-xl font-bold tracking-tight text-gray-900 inline-block md:absolute md:left-1/2 md:transform md:-translate-x-1/2">
          <EditableText
            value={projectName ?? ''}
            onChange={(currentProjectName) => {
              handleSaveProject(currentProjectName);
            }}
            onFocus={() => (userSignedIn ? undefined : openSignIn())}
          />
        </h1>
        {/* Right side toolbar items */}
        <div
          className={`flex flex-wrap justify-between items-end w-full md:w-auto`}
        >
          {/* Download button */}
          <ToolbarButton
            onClick={download}
            className={`${buttonStyles} mr-2`}
            label={<span className="hidden tablet:inline">Download</span>}
            icon={
              <GrDownload className={`tablet:mr-2 mr-0 ${inlineIconStyles}`} />
            }
          />

          {/* Sharing popup */}
          <Popup
            open={sharePopupOpen}
            callback={(open) => {
              if (open) {
                // Save the project so it has a URL.
                handleSaveProject();
                // Ensure the user is signed in before opening this modal.
                userSignedIn ? setSharePopupOpen(true) : openSignIn();
              } else {
                // Close the modal.
                setSharePopupOpen(false);
              }
            }}
            Icon={
              <GrShareOption
                size="18"
                className={`tablet:mr-2 ${inlineIconStyles}`}
              />
            }
            label={<span className="hidden tablet:inline">Share</span>}
          >
            <ShareView
              projectId={currentProjectId || ''}
              onProjectUpdated={(updatedData) => {
                updateProject(currentProjectId, updatedData);
              }}
              projectData={
                projects && currentProjectId && projects[currentProjectId]
              }
            />
          </Popup>
          {/* Launch button */}
          {currentProjectId && (
            <ToolbarButton
              onClick={() => {
                userSignedIn
                  ? window.open(`/run/${currentProjectId}`, '_blank')
                  : openSignIn();
              }}
              className={`${buttonStyles} mr-2`}
              label={<span className="hidden tablet:inline">Launch</span>}
              icon={
                <MdLaunch
                  size="18"
                  className={`inline tablet:mr-1 ${inlineIconStyles}`}
                />
              }
            />
          )}

          {/* Plan Upgrade Button */}
          {(!plan || plan === PLANS.FREE) && (
            <UpgradeButton
              onClick={() => {
                setShowUpgradeModal(true);
              }}
            />
          )}

          {/* User Dropdown */}
          <UserDropdown currentProjectId={currentProjectId} />
        </div>
      </div>{' '}
      {showUpgradeModal && (
        <UpgradeModal
          open={showUpgradeModal}
          isNewProjectAllowed={isNewProjectAllowed || false}
          onClose={() => {
            setShowUpgradeModal(false);
            if (userSignedIn) {
              setProjectsPopupOpen(true);
            }
          }}
        />
      )}
    </>
  );
};
