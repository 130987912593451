import { ProjectList } from '../hooks/projects';
import { MdDelete } from 'react-icons/md';
import { RiTestTubeFill } from 'react-icons/ri';
import { MdAdd } from 'react-icons/md';
import { Button } from './shared/Button';
import Spinner from './Spinner';
export default function ProjectsView({
  projects,
  callback,
  deleteCallback,
  toggleUpgradeModal,
  projectsLoading,
  isNewProjectAllowed,
}: {
  projects: ProjectList;
  callback: (project: string | null) => void;
  deleteCallback: (projectId: string) => void; // Define the delete callback function
  toggleUpgradeModal: () => void;
  projectsLoading?: boolean;
  isNewProjectAllowed?: boolean;
}) {
  const projectsIds = Object.keys(projects ?? {});

  const handleCreateProject = () => {
    if (!isNewProjectAllowed) {
      toggleUpgradeModal();
      return;
    }

    callback(null);
  };

  return (
    <>
      <div className="px-2 pb-2">
        <div className="flex justify-between items-center mb-6">
          <h3 className="text-2xl font-medium text-gray-900 dark:text-white">
            Projects
          </h3>
          <Button
            Icon={<MdAdd className="inline" size={20} />}
            label="New project"
            variant="primary"
            className="hover:border-slate-300 border border-slate-200 rounded-md flex transition transform hover:cursor-pointer justify-center items-center py-2 pl-2 pr-3 text-lg"
            onClick={handleCreateProject}
          />
        </div>

        <div
          className={
            projectsIds.length === 0
              ? 'flex flex-col items-center gap-5 mt-4'
              : 'grid grid-cols-2 gap-5 mt-4'
          }
        >
          {projectsLoading ? (
            <Spinner />
          ) : projectsIds.length === 0 ? (
            <div className="mx-auto text-center text-gray-500">
              No projects yet. Click "Create a new project" to get started!
            </div>
          ) : (
            projectsIds.map((id) => (
              <div
                key={id}
                className="bg-slate-100 hover:border-slate-300 border border-slate-200 rounded-md p-4 flex transition transform hover:cursor-pointer relative group"
                onClick={() => {
                  callback(id);
                }}
              >
                <RiTestTubeFill
                  className="text-2xl inline mr-2"
                  color="#2563eb"
                />

                <h2
                  className="inline-block font-semibold overflow-hidden overflow-ellipsis whitespace-nowrap"
                  title={projects[id].name}
                  style={{ maxWidth: '80%' }}
                >
                  {projects[id].name}
                </h2>
                <MdDelete
                  size={22}
                  className="text-gray-500 hover:text-gray-700 cursor-pointer absolute top-1/2 right-2 transform -translate-y-1/2 opacity-0 group-hover:opacity-100"
                  onClick={(event: React.MouseEvent) => {
                    event.stopPropagation(); // Prevent event propagation
                    const shouldDelete = window.confirm(
                      `Are you sure you want to delete "${projects[id].name}"?`,
                    );
                    if (shouldDelete) {
                      deleteCallback(id);
                    }
                  }}
                />
              </div>
            ))
          )}
        </div>
      </div>
    </>
  );
}
