// Sandpack imports
import { SandpackProvider } from '@codesandbox/sandpack-react';
import projectTemplate from './sandpack/projectTemplate';

// Hooks
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

// Components
import { ProjectPreview } from './components/ProjectPreview';
import { ProjectEditor } from './components/ProjectEditor';
import Spinner from './components/Spinner';

// The project editor page
export default function ProjectEditorPage() {
  // Get the project ID from the URL.
  const { pathname } = useLocation();
  const parts = pathname.split('/').filter((part) => part !== '');
  const page = parts[0];
  const isTemplate = page === 'template';
  const isSignInRedirect = page === 'signin';
  const urlProjectId = isTemplate ? null : parts[1];

  const [files, setFiles] = useState(projectTemplate.files);
  const [filesLoaded, setFilesLoaded] = useState(false);

  useEffect(() => {
    async function fetchData() {
      const templateName = parts[1];
      const fetchFile = async (fileName: string) => {
        const response = await fetch(
          `https://raw.githubusercontent.com/gitwitorg/gitwit-templates/main/${templateName}/${fileName}`,
        );
        return await response.text();
      };
      // Update the 'files' state with the fetched 'App.js' code
      setFiles({
        ...files,
        '/src/App.js': await fetchFile('src/App.js'),
        '/package.json': await fetchFile('package.json'),
      });
      setFilesLoaded(true);
    }

    if (isTemplate) {
      fetchData();
    }

    if (isSignInRedirect) {
      const storedData = localStorage.getItem(`project_${urlProjectId}`);
      setFiles(JSON.parse(storedData ?? '{}'));
      setFilesLoaded(true);
    }
  }, []);

  return (!isTemplate && !isSignInRedirect) || filesLoaded ? (
    <>
      <SandpackProvider
        template="react"
        files={files}
        options={projectTemplate.options}
      >
        {page === 'run' ? (
          // Show the deployed project with no editor.
          <ProjectPreview urlProjectId={urlProjectId} />
        ) : (
          // Show the editor.
          <ProjectEditor urlProjectId={urlProjectId} />
        )}
      </SandpackProvider>
    </>
  ) : (
    <div className="flex h-screen w-screen items-center justify-center">
      <Spinner />
    </div>
  );
}
