export const options = {
  externalResources: ['https://cdn.tailwindcss.com'],
  activeFile: '/src/App.js',
};

export const files = {
  '/public/index.html': `\
<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <script src="https://cdn.tailwindcss.com/"></script>
    <title>Document</title>
  </head>
  <body>
    <div id="root"></div>
  </body>
</html>\
`,
  '/src/App.js': `\
import React from 'react'

export default function App() {
  return (
    <div className="flex justify-center items-center h-screen">
        <h1 className="font-semibold">
          Welcome to my app!
        </h1>
    </div>
  )
}\
`,
  '/src/index.js': `\
import React, { StrictMode } from "react";
import { createRoot } from "react-dom/client";

import App from "./App";

const root = createRoot(document.getElementById("root"));
root.render(
  <StrictMode>
    <App />
  </StrictMode>
);\
`,
  '/package.json': `\
{
  "dependencies": {
    "react": "^18.0.0",
    "react-dom": "^18.0.0",
    "react-scripts": "^4.0.0"
  },
  "scripts": {
    "start": "react-scripts --openssl-legacy-provider start"
  },
  "main": "./src/index.js",
  "devDependencies": {},
  "browserslist": {
    "production": [
      ">0.2%",
      "not dead",
      "not op_mini all"
    ],
    "development": [
      "last 1 chrome version",
      "last 1 firefox version",
      "last 1 safari version"
    ]
  }
}\
`,
  '/README.md': `\
# My React App
This project was generated with GitWit. To run the app locally run:
\`\`\`
yarn install
yarn start
\`\`\`\
`,
};

export default { files, options };
